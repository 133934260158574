interface Company {
  [key: string]: any;
}

const companies: Company = {
  grafana: { name: 'Grafana Labs', image_name: 'grafana_logo.png' },
  bytedance: { name: 'ByteDance', image_name: 'bytedance_logo.png' },
  instacart: { name: 'Instacart', image_name: 'instacart_logo.png' },
  airtable: { name: 'Airtable', image_name: 'airtable_logo.png' },
  discord: { name: 'Discord', image_name: 'discord_logo.png' },
  figma: { name: 'Figma', image_name: 'figma_logo.png' },
  canva: { name: 'Canva', image_name: 'canva_logo.png' },
  stripe: { name: 'Stripe', image_name: 'stripe_logo.png' },
  zapier: { name: 'Zapier', image_name: 'zapier_logo.png' },
  mailchimp: { name: 'Mailchimp', image_name: 'mailchimp_logo.png' },
  notion: { name: 'Notion', image_name: 'notion_logo.png' },
  webflow: { name: 'Webflow', image_name: 'webflow_logo.png' },
  onetrust: { name: 'OneTrust', image_name: 'onetrust_logo.jpg' },
  gong: { name: 'Gong', image_name: 'gong_logo.png' },
  benchling: { name: 'Benchling', image_name: 'benchling_logo.png' },
  m1: { name: 'M1 Finance', image_name: 'm1_logo.png' },
  gitlab: { name: 'GitLab', image_name: 'gitlab_logo.jpg' },
  'hinge-health': { name: 'Hinge Health', image_name: 'hinge-health_logo.png' },
  retool: { name: 'Retool', image_name: 'retool_logo.png' },
  'modern-health': { name: 'Modern Health', image_name: 'modern-health_logo.jpg' },
  hopin: { name: 'Hopin', image_name: 'hopin_logo.png' },
  akasa: { name: 'Akasa Europe', image_name: 'akasa_logo.jpg' },
  'modern-treasury': { name: 'Modern Treasury', image_name: 'modern-treasury_logo.png' },
  hotmart: { name: 'Hotmart', image_name: 'hotmart_logo.png' },
  reddit: { name: 'Reddit', image_name: 'reddit_logo.png' },
  public: { name: 'Public.com', image_name: 'public_logo.jpg' },
  'cockroach-labs': { name: 'Cockroach Labs', image_name: 'cockroach-labs_logo.png' },
  anchore: { name: 'Anchore', image_name: 'anchore_logo.png' },
  cerebral: { name: 'Cerebral', image_name: 'cerebral_logo.png' },
  foresight: { name: 'Foresight', image_name: 'foresight_logo.png' },
  'trade-republic': { name: 'Trade Republic', image_name: 'trade-republic_logo.png' },
  'checkout-com': { name: 'Checkout.com', image_name: 'checkout-com_logo.png' },
  contentful: { name: 'Contentful', image_name: 'contentful_logo.png' },
  netlify: { name: 'Netlify', image_name: 'netlify_logo.png' },
  scale: { name: 'ScaleAI', image_name: 'scale_logo.png' },
  rivian: { name: 'Rivian', image_name: 'rivian_logo.png' },
  melio: { name: 'Melio', image_name: 'melio_logo.png' },
  algolia: { name: 'Algolia', image_name: 'algolia_logo.png' },
  blockfi: { name: 'BlockFi', image_name: 'blockfi_logo.png' },
  starburst: { name: 'Starburst', image_name: 'starburst_logo.png' },
  wise: { name: 'Wise', image_name: 'wise_logo.png' },
  finix: { name: 'Finix', image_name: 'finix_logo.png' },
  talkdesk: { name: 'Talkdesk', image_name: 'talkdesk_logo.png' },
  sourcegraph: { name: 'Sourcegraph', image_name: 'sourcegraph_logo.png' },
  self: { name: 'Self', image_name: 'self_logo.png' },
  confluent: { name: 'Confluent', image_name: 'confluent_logo.png' },
  chime: { name: 'Chime', image_name: 'chime_logo.png' },
  spacex: { name: 'SpaceX', image_name: 'spacex_logo.png' },
  hashicorp: { name: 'HashiCorp', image_name: 'hashicorp_logo.png' },
  mux: { name: 'Mux', image_name: 'mux_logo.png' },
  'observe-ai': { name: 'Observe.AI', image_name: 'observe-ai_logo.jpg' },
  chainalysis: { name: 'Chainalysis', image_name: 'chainalysis_logo.png' },
  dataiku: { name: 'Dataiku', image_name: 'dataiku_logo.png' },
  instabase: { name: 'Instabase', image_name: 'instabase_logo.jpg' },
  truepill: { name: 'Truepill', image_name: 'truepill_logo.jpg' },
  gorgias: { name: 'Gorgias', image_name: 'gorgias_logo.png' },
  revolut: { name: 'Revolut', image_name: 'revolut_logo.png' },
  klarna: { name: 'Klarna', image_name: 'klarna_logo.png' },
  alto: { name: 'Alto', image_name: 'alto_logo.png' },
  'misfits-market': { name: 'Misfits Market', image_name: 'misfits-market_logo.png' },
  cameo: { name: 'Cameo', image_name: 'cameo_logo.png' },
  masterclass: { name: 'MasterClass', image_name: 'masterclass_logo.png' },
  medable: { name: 'Medable', image_name: 'medable_logo.png' },
  stord: { name: 'Stord', image_name: 'stord_logo.jpg' },
  pilot: { name: 'Pilot', image_name: 'pilot_logo.png' },
  'go-puff': { name: 'goPuff', image_name: 'go-puff_logo.png' },
  'up-keep': { name: 'UpKeep', image_name: 'up-keep_logo.png' },
  hyperscience: { name: 'Hyperscience', image_name: 'hyperscience_logo.png' },
  'tray-io': { name: 'Tray.io', image_name: 'tray-io_logo.png' },
  ro: { name: 'Ro', image_name: 'ro_logo.png' },
};

export const getCompanyData = (company: any) => {
  const exists = Boolean(Object.keys(companies).includes(company));
  console.log('exists: ', exists);
  if (!exists) {
    return undefined;
  }

  const ret = companies[company];
  console.log('ret: ', ret);
  return ret;
};
