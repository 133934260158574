import * as React from 'react';
import AOS from 'aos';
import { useEffect, useState } from 'react';
import './bootstrap.css';
import 'aos/dist/aos.css';
import './styles.css';
import './sal.css';
import styled from 'styled-components';
import { getCompanyData } from './companies';
import { mobileSize } from '../../../util/variables';
import { AnimatedLinkAnchor, AppText } from '../../ui';
import theme from '../../../util/theme';
require(typeof window !== 'undefined' ? './lottie.js' : '../../../util/theme');

const S3_COMPANY_IMAGE_BUCKET_BASE = 'https://base10-company-images.s3-us-west-1.amazonaws.com/';

const ForeignSegment: React.FC = () => {
  const [company, setCompany] = useState(undefined);
  // const companySlug = 'grafana';
  useEffect(() => {
    AOS.init({ easing: 'ease-in-out', duration: 500, delay: 0 });
    const companySlug = location.pathname.split('/')[2];
    console.log('location.pathname: ', location.pathname);
    console.log('company: ', companySlug);
    const company = getCompanyData(companySlug);
    console.log('company: ', company);
    setCompany(company);
  }, []);

  const isBrowser = typeof window !== 'undefined';

  return (
    <div className="full-content">
      <section className="cover">
        <div className="container">
          {/* new stuff start */}
          {/* <div id="cover-main-content"> */}
          <div
            id="cover-main-container"
            // style="--background_url=url(../../../../static/img/Group1.png) no-repeat center"
          >
            {/* <MainContent> */}
            <h1
              id="main-content-h1"
              style={
                {
                  '--bgdisplay': !isBrowser || Boolean(company) ? 'none' : 'flex',
                } as React.CSSProperties
              }
            >
              The next $B+ <br /> minority-led startup <br /> starts here.
            </h1>
            {Boolean(company?.image_name) && (
              <CompanyImage src={`${S3_COMPANY_IMAGE_BUCKET_BASE}${company.image_name}`} />
            )}
            {/* </MainContent> */}
            {/* <img id="small-icons" src="../img/Group1.png" width="405px" alt="" /> */}
            {/* </div> */}
          </div>
          <div>
            <div className="row">
              <div className="col" id="cover-arrow">
                {/* <div className="arrow-coins-lottie"> */}
                <lottie-player
                  loop
                  autoplay
                  style={{ marginBottom: '-8px' }}
                  id="arrow-graph cover-arrow"
                  src="../lottie/arrow_coins.json"
                  background="transparent"
                  speed="1"
                />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div id="cover-bottom">
          <div className="row">
            <div className="col-8">
              <h1 id="cover-bottom-h1">The Advancement Initiative</h1>
            </div>
            <div className="col-4" />
          </div>
          <div className="row line-section">
            <div className="col">
              <div className="partners-logo">
                <img className="mobile" src="../img/base10_partners_w.svg" />
                <img className="desktop" src="../img/base10_partners_desktop_w.svg" />
              </div>
              <div className="icon-cover">
                <img src="../img/icon_uni_w.svg" />
              </div>
              <div className="blue-line" />
            </div>
          </div>
          <div className="row">
            <div className="col-4 hide-cover" />
            <div className="col-8 right">
              <h3 className="light-font">
                {/* Helping ensure underrepresented minorities have the chance to be the founders of the
                next billion-dollar startups */}
              </h3>
            </div>
          </div>
        </div>
        {/* now stuff end */}
        {/* old stuff start */}
        {/* <div className="content-cover">
            <div className="row">
              <div className="col-8">
                <h1>
                  The <br /> Advancement Initiative
                </h1>
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row line-section">
              <div className="col">
                <div className="partners-logo">
                  <img className="mobile" src="../img/base10_partners_w.svg" />
                  <img className="desktop" src="../img/base10_partners_desktop_w.svg" />
                </div>
                <div className="icon-cover">
                  <img src="../img/icon_uni_w.svg" />
                </div>
                <div className="blue-line"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 hide-cover"></div>
              <div className="col-8 right">
                <h3 className="light-font">
                  Helping ensure underrepresented minorities have the chance to be the founders of
                  the next billion-dollar startups
                </h3>
              </div>
            </div>
          </div>
        </div> */}
        {/* old stuff end */}
      </section>
      {/* <section className="intro">
        <div className="container">
          <h3
            style={{ marginTop: '0px' }}
            data-sal="slide-right"
            data-sal-delay="200"
            data-sal-duration="700"
            data-sal-easing="easeInOutQuad"
          >
            <span className="blue">
              The Base10 Advancement Initiative (“AI” or “the Fund”) is a $250M Fund
            </span>{' '}
            that aligns the financial success of today’s top technology companies with the wealth
            creation for underrepresented minorities.
          </h3>
          <p
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-duration="700"
            data-sal-easing="easeInOutQuad"
          >
            In collaboration with our portfolio companies and our limited partners, the AI’s goal is
            to produce a generation of underrepresented minority founders and executives.
          </p>
        </div>
      </section> */}

      <section className="meet light">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1
                style={{ marginBottom: '8px', maxWidth: '1080px' }}
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                Enabling {company?.name ? `${company?.name}` : 'today’s top tech companies'} to lay
                the foundation for tomorrow’s billion-dollar, minority-led startups.
              </h1>
            </div>
          </div>
          {/* <div className="row">
            <div className="col" style={{ maxWidth: '414px' }}>
              <p
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
                className="sub-title"
              >
                The result could be <span className="blue">$21.3B</span> that would otherwise be
                left on the table.
              </p>
            </div>
            <div className="col hide-small-mobile"></div>
            <div className="col hide-tablet"></div>
          </div> */}
          <div className="row calendar">
            <div className="col">
              <div className="calendar-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ height: '361px' }}
                  src="../lottie/calendar.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
            <div className="col-1" />
            <div
              className="col"
              data-sal="slide-left"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div>
                {/* Over the next decade, the mission of the Advancement Initiative is{' '}
                <strong>
                  to create financial aid for <span className="blue">100,000 students</span>
                </strong>{' '}
                at Historically Black Colleges & Universities (HBCUs), contributing up to{' '}
                <span className="blue">
                  <strong>$9.3B in incremental earnings</strong>
                </span>{' '}
                for minority graduates and their communities from an education they might not have
                otherwise had.
              </div>
              <div className="quote-info right">
                HBCU graduates make $926,666 in lifetime earnings on top of what they would have
                earned without the degree. 100,000 graduates have the potential to generate $9.3B of
                wealth.
              </div> */}
                <h3>The Base10 Advancement Initiative</h3>
                <h1>$250M Fund</h1>
                <h3>with the goal to create financial aid for</h3>
                <h1>100,000 HBCU students</h1>
                <h3>who will become founders of</h3>
                <h1>tomorrow’s $B+ startups</h1>
              </div>
            </div>

            {/* <div className="row arrow-coins">
            <div
              className="col"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="graph-description">
                Through these student scholarships{' '}
                <strong>our goal is to support the education of ten $10B company founders</strong>,
                which have the potential to generate up to $21.3B in annual GDP.
              </div>
              <div className="quote-info">
                Based on public company data for U.S. companies and economic reports from The
                Internet Association, an average U.S. public tech company with $10 billion in market
                capitalization generates $2.13 billion in GDP annually.
              </div>
            </div>
            <div className="col"></div>
          </div> */}

            {/* <div className="row arrow-graph">
          <div className="col">
              <div className="arrow-coins-lottie">
                <lottie-player
                  style={{ marginBottom: '-8px' }}
                  src="../lottie/arrow_coins.json"
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="dark">
        <div className="container">
          <div className="row">
            <div
              className="col"
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <h1>How the Advancement Initiative works</h1>
            </div>
          </div>

          <div className="row how-it-works">
            <div className="col">
              <div className="how-it-works-graph">
                <div
                  className="each-block"
                  data-sal="fade"
                  data-sal-delay="100"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="circle">
                    <img className="how_1" src="../img/how_1.svg" />
                  </div>
                  <div className="text-circle">
                    Base10 <br /> Growth Fund
                  </div>
                </div>

                <div
                  className="each-block"
                  data-sal="fade"
                  data-sal-delay="200"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >

                {/* {Boolean(isBrowser) && ( */}
                  <div
                    style={{
                      backgroundColor: company ? '#fff' : '#45adea',
                      borderWidth: company ? 3 : 0,
                      borderColor: '#45adea',
                      borderStyle: 'solid',
                    }}
                    className="circle"
                  >
                    <img
                      className={company ? 'how_2_company' : 'how_2'}
                      src={
                        Boolean(company) && Boolean(company?.image_name)
                          ? `${S3_COMPANY_IMAGE_BUCKET_BASE}${company?.image_name}`
                          : '../img/how_2.svg'
                      }
                    />
                  </div>
                  <div className="text-circle">
                    Base10 invests in <br /> {company ? 'your company' : 'Pre-IPO companies'}
                  </div>
                </div>

                <div
                  className="each-block"
                  data-sal="fade"
                  data-sal-delay="400"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="circle">
                    <img className="how_4" src="../img/how_4.svg" />
                  </div>
                  <div className="text-circle">
                    Donate 50% of <br /> returns to HBCUs
                  </div>
                </div>

                <div
                  className="each-block"
                  data-sal="fade"
                  data-sal-delay="500"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="circle">
                    <img className="how_5" src="../img/how_5.svg" />
                  </div>
                  <div className="text-circle">
                    Fund scholarships
                    <br />
                    for minority students
                  </div>
                </div>

                <div
                  className="each-block"
                  data-sal="fade"
                  data-sal-delay="600"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="circle">
                    <img className="how_6" src="../img/how_6.svg" />
                  </div>
                  <div className="text-circle">
                    Increase tech
                    <br />
                    talent pipeline
                  </div>
                </div>

                <div
                  className="each-block"
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="circle">
                    <img className="how_3" src="../img/how_3.svg" />
                  </div>
                  <div className="text-circle">
                    Find next generation <br /> of $1B founders
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row companies-partners">
            <div
              className="col-sm companies"
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div style={{ fontSize: '32px' }} className="title-meet">
                Companies
              </div>
              <div className="graph-description">
                <strong>The AI invests in leading growth-stage tech companies </strong>who are
                committed to helping generate billions of dollars in financial aid for 100,000+ HBCU
                students through participation in this Fund.
              </div>
              <div className="logos">
                <img src="../img/partners/w_attentive.png" />
                <img src="../img/partners/w_plaid.png" />
                <img src="../img/partners/w_aurora.png" />
                <img src="../img/partners/w_keeptruckin.png" />
                <img src="../img/partners/w_nubank.png" />
                <img src="../img/partners/w_brex.png" />
                <img src="../img/partners/w_wealthsimple.png" />
                <img src="../img/partners/w_circle.png" />
              </div>
              <a href="/advancement-initiative/companies">
                <button
                  type="button"
                  style={{ fontSize: '20px', minHeight: '48px' }}
                  className="w-100 btn btn-lg btn-primary"
                >
                  Meet Our Portfolio Companies
                </button>
              </a>
            </div>
            <div className="col-sm hide-mobile">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
                className="birrete_middle-lottie"
              >
                <lottie-player
                  loop
                  autoplay
                  src="../lottie/birrete_middle.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
            <div
              className="col-sm partners"
              data-sal="slide-left"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div style={{ fontSize: '32px' }} className="title-meet">
                Partners
              </div>
              <div className="graph-description">
                <strong>
                  HBCUs are the AI’s largest LPs, alongside select foundations, institutions, and
                  other minority-focused organizations
                </strong>{' '}
                who will help ensure students receive the maximum benefit from this investment.
              </div>
              <div className="logos">
                <img src="../img/partners/w_howard.png" />
                <img src="../img/partners/w_tuskegee.png" />
                {/* <img className="uncf_w" src="../img/partners/w_uncf.png" /> */}
                {/* <img src="../img/partners/w_hampton.png" /> */}
                <img src="../img/partners/w_macarthur.png" />
                <img src="../img/partners/w_kff.png" />
                <img className="famu_w" src="../img/partners/w_ford.png" />
              </div>
              <a href="/advancement-initiative/partners">
                <button
                  type="button"
                  style={{ fontSize: '20px', minHeight: '48px' }}
                  className="w-100 btn btn-lg btn-primary"
                >
                  Meet Our Partners
                </button>
              </a>
            </div>
          </div>
        </div>

        <section
          style={{ marginTop: '50px', marginBottom: '0px' }}
          className="quotes-partners dark"
        >
          <div className="container">
            <div className="row quotes-block">
              <div
                className="col"
                data-sal="fade"
                data-sal-delay="250"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="quote-meet">
                  HBCUs and their endowments have been historically excluded from investments into
                  venture capital and technology, and the Advancement Initiative not only grants
                  these crucial institutions access, but helps prioritize them in incredibly
                  competitive deals. We are super excited about our partnership with Base10.
                </div>
                <div className="quote-meet-info right marcelia">
                  <div className="name-quote">Marcelia Freeman</div>
                  <div className="position-quote">
                    Board of Directors
                    <br />
                    Florida A&M University
                  </div>
                </div>
              </div>

              <div className="col-1 hide-mobile" />
              <div
                className="col"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="quote-meet">
                  The Advancement Initiative creates a clear path for us to contribute to the
                  success of underrepresented minority students who will go on to be the leaders and
                  innovators of tomorrow, and we couldn’t be more excited to be participating.
                </div>
                <div className="quote-meet-info right zach">
                  <div className="name-quote">Zach Perret</div>
                  <div className="position-quote">
                    Co-founder and CEO
                    <br />
                    Plaid
                  </div>
                </div>
              </div>

              <div className="col-1 hide-mobile" />
              <div
                className="col"
                data-sal="fade"
                data-sal-delay="200"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="quote-meet">
                  We were blown away by Base10’s innovative, unique and generous approach to
                  advancing diversity in tech. Aurora couldn’t be more proud to be an Advancement
                  Initiative portfolio company, and to thereby help create opportunities for
                  talented students who will become the next generation of tech company leaders.
                </div>
                <div className="quote-meet-info right adeyemo">
                  <div className="name-quote">Sam Adeyemo</div>
                  <div className="position-quote">
                    Co-founder
                    <br />
                    Aurora Solar
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <ArticleSection>
        <OurPressListWrapper>
          <PressItem key="article-1">
            <a
              target="_blank"
              href="https://www.bloomberg.com/news/articles/2021-05-27/advancement-initiative-wants-to-steer-hbcu-endowments-into-venture-capital?sref=xHB8gqoghttps://"
            >
              <Pressimg
                target="_blank"
                src="https://images.prismic.io/base10/a73f3fb8-c658-469a-a8ab-9da7db8ff481_Screen+Shot+2021-05-28+at+1.19.54+PM.png?auto=compress,format&rect=44,0,1234,696&w=250&h=141"
                alt="base10 ceo"
              />
              <Pressh4>May 2021 / Bloomberg</Pressh4>
              <Pressh3>A $250 Million Fund Steers HBCU Endowments Into Venture Capital</Pressh3>
              {/* <Pressp>
                  The Advancement Initiative aims to help less prosperous schools bridge the wealth
                  gap through investments in late-stage startups. &nbsp;
                  <AnimatedLinkAnchor
                    fontSize="20px"
                    paddingBottom="1px"
                    to="https://www.bloomberg.com/news/articles/2021-05-27/advancement-initiative-wants-to-steer-hbcu-endowments-into-venture-capital?sref=xHB8gqoghttps://"
                  >
                    Read More
                  </AnimatedLinkAnchor>
                </Pressp> */}
            </a>
          </PressItem>
          <PressItem key="article-2">
            <a
              target="_blank"
              href="https://techcrunch.com/2021/05/27/this-250-million-growth-fund-will-divert-half-its-profits-to-historically-black-colleges-and-universities/"
            >
              <Pressimg
                target="_blank"
                src="https://images.prismic.io/base10/d1f581c1-79a1-40ef-b1c5-3deda774891c_8V9A2263+copy.jpg?auto=compress,format&rect=70,0,1954,1102&w=250&h=141"
                alt="base10 team"
              />
              <Pressh4>May 2021 / TechCrunch</Pressh4>
              <Pressh3>
                This $250 million growth fund will divert half its profits to historically Black
                colleges and universities
              </Pressh3>
              {/* <Pressp>
                  There’s been a lot of talk about racial equality in the year since George Floyd
                  was murdered in Minneapolis, but achieving it is far easier said than done given
                  the current state of affairs. &nbsp;
                  <AnimatedLinkAnchor
                    fontSize="20px"
                    paddingBottom="1px"
                    to="https://techcrunch.com/2021/05/27/this-250-million-growth-fund-will-divert-half-its-profits-to-historically-black-colleges-and-universities/"
                  >
                    Read More
                  </AnimatedLinkAnchor>
                </Pressp> */}
            </a>
          </PressItem>
          <PressItem key="article-3">
            <a
              target="_blank"
              href="https://www.businessinsider.com/base10-new-fund-to-support-hbcu-brex-plaid-attentive-2021-5"
            >
              <Pressimg
                target="_blank"
                src="https://images.prismic.io/base10/d1f581c1-79a1-40ef-b1c5-3deda774891c_8V9A2263+copy.jpg?auto=compress,format&rect=70,0,1954,1102&w=250&h=141"
                alt="base10 team"
              />
              <Pressh4>May 2021 / Business Insider</Pressh4>
              <Pressh3>
                A new $250 million fund from venture capital firm Base10 has a portfolio full of
                unicorns like Plaid and Brex. The returns will go to historically Black colleges.
              </Pressh3>
              {/* <Pressp>
                  Base10's new fund has investors that include HBCUs like Howard and Florida A&M.
                  The firm will also use some of its profits to fund scholarships. &nbsp;
                  <AnimatedLinkAnchor
                    fontSize="20px"
                    paddingBottom="1px"
                    to="https://www.businessinsider.com/base10-new-fund-to-support-hbcu-brex-plaid-attentive-2021-5"
                  >
                    Read More
                  </AnimatedLinkAnchor>
                </Pressp> */}
            </a>
          </PressItem>
        </OurPressListWrapper>
      </ArticleSection>

      <section className="light hbcus">
        <div className="container">
          <div className="row">
            <div className="col right">
              <h1
                style={{ marginBottom: '8px' }}
                data-sal="slide-left"
                data-sal-delay="200"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                We are starting with HBCUs, the #1 source of diverse talent in the U.S. with an
                outsized impact. HBCUs produce:<sup>1</sup>
              </h1>
            </div>
          </div>
          {/* <div className="row hbcu-first">
            <div className="col-4 hide-mobile"></div>
            <div className="col-8 right">
              <div
                className="sub-title"
                data-sal="slide-left"
                data-sal-delay="300"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                With the potential to radically decrease racial inequality, HBCUs produce:
                <sup>1</sup>
              </div>
            </div>
          </div> */}

          <div className="row hbcu-produce">
            <div className="col">
              <div className="item">
                <div
                  className="info graduates-icon"
                  data-sal="fade"
                  data-sal-delay="200"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="percentage">23%</div>
                  <p style={{ fontSize: '16px', lineHeight: 1.5 }}>
                    of all Black U.S. <strong>graduates</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile" />
            <div className="col">
              <div className="item">
                <div
                  className="info degrees-icon"
                  data-sal="fade"
                  data-sal-delay="300"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="percentage">40%</div>
                  <p style={{ fontSize: '16px', lineHeight: 1.5 }}>
                    of all Black U.S. <strong>STEM degrees</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile" />
            <div className="col">
              <div className="item">
                <div
                  className="info alldegrees-icon"
                  data-sal="fade"
                  data-sal-delay="400"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  <div className="percentage">60%</div>
                  <p style={{ fontSize: '16px', lineHeight: 1.5 }}>
                    of all Black U.S. <strong>engineering degrees</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row howard-university">
            <div
              className="col-4"
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="graph-description">
                Howard University, which is one of the largest HBCUs and an LP in this Fund,{' '}
                <strong>produces as many PhDs as Harvard, MIT, Yale, and Stanford combined.</strong>
                <sup>2</sup>
              </div>

              <div className="quote-info">
                <div style={{ zIndex: 10 }}>
                  1:{' '}
                  <a
                    style={{ textDecoration: 'underline', zIndex: 9 }}
                    href="https://www.hcpss.org/f/schools/bsap/hbcu-facts-figures.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HCPSS
                  </a>
                  ,{' '}
                  <a
                    style={{ textDecoration: 'underline', zIndex: 9 }}
                    href="https://cmsi.gse.rutgers.edu/sites/default/files/Changing_Face_HBCUs.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Penn GSE
                  </a>
                </div>
                <div>
                  2:{' '}
                  <a
                    style={{ textDecoration: 'underline', zIndex: 9 }}
                    href="https://www.theatlantic.com/education/archive/2019/05/howard-universitys-president-why-america-needs-hbcus/589582/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Howard University
                  </a>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="universities-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ height: '475px' }}
                  src="../lottie/universities.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="grey hbcus">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                Despite these stats, HBCUs have tragically tiny endowments compared to their similar
                sized peers
              </h1>
            </div>
          </div>

          <div className="row books">
            <div
              className="col"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="graph-description">
                <strong>
                  The average endowment size of the top 5 HBCUs is $331M compared to $28B for the
                  top 5 largest U.S. university endowments.
                </strong>
              </div>
              {/* <p>
                Only 7.92% of students from the top U.S. universities are black, further
                contributing to the consequences of this inequity.
              </p> */}
              <div className="quote-info full">
                Sources:{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.usnews.com/education/best-colleges/the-short-list-college/articles/10-universities-with-the-biggest-endowments"
                >
                  U.S. News
                </a>
                ,{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://hbcumoney.com/tag/hbcu-endowments/"
                >
                  HBCU Money
                </a>
                , and School Diversity Reports.
              </div>
            </div>
            <div className="col">
              <div className="books-block">
                <div className="books-lottie">
                  <lottie-player
                    loop
                    autoplay
                    style={{ height: '395.5px' }}
                    src="../lottie/books.json"
                    background="transparent"
                    speed="1"
                  />
                </div>
                <div className="books-leyend">
                  <div className="top5hbcu">Top 5 HBCUs</div>
                  <div className="top5us">Top 5 U.S. Universities Endowments</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pencil">
            <div className="col col-graph">
              <div className="pencil-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ height: '417px' }}
                  src="../lottie/pencil.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
            <div className="col col-description">
              <div
                className="graph-description"
                data-sal="slide-left"
                data-sal-delay="200"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                To make matters worse, 30% of all HBCU students live{' '}
                <strong>below the poverty</strong> line when attending their HBCU.
              </div>
            </div>
          </div>

          <div className="row keys">
            <div className="col">
              <div
                className="graph-description"
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                HBCU endowments have been{' '}
                <strong>
                  historically excluded from investment access to the best technology companies
                </strong>{' '}
                in the world.
              </div>
              <div
                className="graph-description"
                data-sal="fade"
                data-sal-delay="500"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <span className="light-font blue">
                  The Advancement Initiative is ready to change this.
                </span>
              </div>
            </div>
            <div className="col">
              <div className="keys-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ height: '570px' }}
                  src="../lottie/keys.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
{/*  */}
      {/* <section className="dark problem">
        <div className="container problem-first">
          <div className="row">
            <div
              className="col right"
              data-sal="slide-left"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <h1>
                The Advancement Initiative aligns the success of the tech industry with wealth
                creation and opportunities for underrepresented minorities by:
              </h1>
            </div>
          </div>

          <div className="row coins">
            <div className="col col-graph hide-small-mobile">
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className="coins-lottie"
              >
                <lottie-player
                  loop
                  autoplay
                  style={{ width: '405px' }}
                  src="../lottie/coins.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
            <div className="col col-description">
              <div
                className="item"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="coin-number">
                  <img className="num_1" src="../img/num_1.svg" />
                </div>
                <div className="title">
                  Investing into <strong>the best growth technology companies</strong> in the world.
                </div>
              </div>
              <div
                className="item"
                data-sal="fade"
                data-sal-delay="400"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="coin-number">
                  <img className="num_2" src="../img/num_2.svg" />
                </div>
                <div className="title">
                  <strong>
                    Sharing 50% of the carried interest from these investments directly with HBCUs
                    to fund thousands of scholarships and financial aid awards for URM students.
                  </strong>
                </div>
              </div>
              <div
                className="item"
                data-sal="fade"
                data-sal-delay="500"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="coin-number">
                  <img className="num_3" src="../img/num_3.svg" />
                </div>
                <div className="title">
                  Offering diversity-as-a-service to portfolio companies which includes:
                </div>
                <ul>
                  <li>Recruiting top HBCU students through the AI’s on-campus training program.</li>
                  <li>Access to our network of URM executives and board members.</li>
                  <li>Representation on their cap tables by HBCUs.</li>
                  <li>
                    Opportunity to collaborate with HBCUs on other internal diversity initiatives.
                  </li>
                </ul>
              </div>

              <div className="quote-info">
                Subsequent AI Funds will expand in network to include additional organizations,
                foundations, and institutions working to increase educational access for URM
                students.
              </div>
            </div>
          </div>
        </div>
        <div className="container problem-second">
          <div className="row mid-title">
            <div
              className="col"
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <h2>Tech still has a long way to go when it comes to diverse talent</h2>
            </div>
            <div className="col hide-mobile" />
          </div>

          <div className="row long-way">
            <div className="col">
              <div
                className="item"
                data-sal="fade"
                data-sal-delay="200"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="info computer-icon">
                  <div className="percentage">6.7%</div>
                  <p>
                    of employees at large tech companies including Facebook, Salesforce, Twitter,
                    and Uber are black.
                  </p>
                </div>
              </div>
              <div
                className="item"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="info tech-icon">
                  <div className="percentage">3.5%</div>
                  <p>
                    of <strong>technical roles</strong> are held by black workers at these same
                    companies.
                  </p>
                </div>
              </div>
              <div
                className="item"
                data-sal="fade"
                data-sal-delay="400"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="info tie-icon">
                  <div className="percentage">Nearly all</div>
                  <p>
                    <strong>tech companies</strong> under-represent black employees in leadership.
                  </p>
                </div>
              </div>
              <div
                className="item last"
                data-sal="fade"
                data-sal-delay="500"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                <div className="info graph-icon">
                  <div className="percentage">13.4%</div>
                  <p>
                    <strong>of the U.S. population is black,</strong> which means this industry is
                    still hugely underrepresenting a critical pool of talented workers.
                  </p>
                </div>
              </div>
              <div
                className="quote-info"
                data-sal="fade"
                data-sal-delay="550"
                data-sal-duration="700"
                data-sal-easing="easeInOutQuad"
              >
                Based on a survey of 17 companies’ publicly-available, self-reported data from 2014
                to 2020, compiled by Base10 and{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://github.com/Base10vc/tech-diversity-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  available on GitHub
                </a>
                .
              </div>
            </div>
            <div className="col hide-small-mobile">
              <div className="office-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ height: '789px' }}
                  src="../lottie/office.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
{/*  */}
      {/* <section className="light diversity">
        <div className="container">
          <div className="row">
            <div
              className="col"
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <h1>
                With calls and promises to improve inequality, tech is trending in the right
                direction, but with slow progress and work still to be done
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col black-workers-col">
              <div className="graph-title">Black Workers in Big Tech</div>
              <div className="black-legend">
                <img src="../img/black_workers_legend.svg" />
              </div>
              <div className="blackworkers-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ height: '100%' }}
                  id="blackworkers-lottie-svg"
                  src="../lottie/black_workers.json"
                  background="transparent"
                  speed="1"
                />
              </div>
              <div className="quote-info">Source: Company reports.</div>
            </div>
          </div>
          <div className="row average-row">
            <div className="col-8 col-graph">
              <div className="graph-title">Average representation by race</div>

              <div className="average-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ height: '100%' }}
                  id="average-lottie-svg"
                  src="../lottie/average.json"
                  background="transparent"
                  speed="1"
                />
              </div>
              <div className="quote-info">Source: Company reports.</div>
            </div>
            <div
              className="col-4 col-description"
              data-sal="slide-left"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="graph-description right">
                When looking at a broader cross-section of{' '}
                <strong>some of the fastest-growing tech companies</strong>, data trends in the
                right direction, but workforce racial diversity is far from resolved
              </div>
            </div>
          </div>

          <div className="row mid-title">
            <div
              className="col"
              data-sal="slide-right"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <h2>Plus, a lack of diversity leaves money on the table</h2>
            </div>
            <div className="col hide-small-mobile" />
          </div>

          <div className="row lack-of-diversity">
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div>
                <div className="ethnic-lottie">
                  <lottie-player
                    loop
                    autoplay
                    style={{ height: '430px' }}
                    src="../lottie/ethnic.json"
                    background="transparent"
                    speed="1"
                  />
                </div>
              </div>
              <div className="graph-description">
                Companies in the top quartile for ethnic and gender diversity{' '}
                <strong>
                  are more likely to surpass industry norms for revenue and operating margin.
                </strong>
              </div>
            </div>
            <div className="col-2" />
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="400"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div>
                <div className="hands-lottie">
                  <lottie-player
                    loop
                    autoplay
                    style={{ height: '430px' }}
                    src="../lottie/hands.json"
                    background="transparent"
                    speed="1"
                  />
                </div>
              </div>
              <div className="graph-description">
                Diversity efforts could net the IT industry an{' '}
                <strong>extra $400 billion in revenue each year.</strong>
              </div>
              <div className="quote-info full">
                Source:{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.techrepublic.com/article/how-much-is-diversity-in-tech-worth-400b-says-comptia-ceo/"
                >
                  Tech Republic
                </a>
                .
              </div>
            </div>
            <div className="col-2 hide-tablet" />
          </div>
        </div>
      </section> */}

      <section className="grey supporting">
        <div className="container">
          <div className="row text-supporting">
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <h3 style={{ marginTop: '0px', marginBottom: '8px' }} className="blue">
                The Advancement Initiative will support underrepresented minority students on their
                journey from HBCU to next-generation startup founder.
              </h3>
              {/* <h3 style={{ marginTop: '0px', marginBottom: '8px' }}>
                The Advancement Initiative is ready to catalyze this change and help generate up to
                $21.3B in economic wealth creation.
              </h3> */}
            </div>
          </div>
          <div className="row">
            <div className="col lottie-col">
              <div className="arrow-footer-lottie">
                <lottie-player
                  loop
                  autoplay
                  style={{ marginBottom: '-7px' }}
                  src="../lottie/arrow.json"
                  background="transparent"
                  speed="1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="dark thank-you">
        <div className="container">
          <div className="col-12">
            <div
              style={{ lineHeight: 1.5 }}
              className="thank-you-text"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="800"
              data-sal-easing="easeInOutQuad"
            >
              Thank you.
            </div>
            <div
              data-sal="slide-right"
              data-sal-delay="500"
              data-sal-duration="1000"
              data-sal-easing="easeInOutQuad"
              className="logo-team"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

const MainContent = styled.h1`
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 88px;
  line-height: 80px;
  color: #45aeeb;
  max-width: 70%;
  /* max-width: 600px; */
  ::after {
    position: absolute;
    content: '';
    z-index: 1;
    background: url(../../../../static/img/Group1.png) no-repeat center;
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: 405px 293px;
    right: -405px;
    top: 0px;
    height: 293px;
    width: 405px;
    /* max-width: 600px; */
  }
`;

const CompanyImage = styled.img`
  max-width: 250px;
  max-height: 192px;
  margin-left: 260px;
  margin-top: -50px;
  @media (max-width: 1400px) {
    max-height: 153px;
    max-width: 200px;
    margin-left: 150px;
  }
  @media (max-width: 1200px) {
    max-height: 122px;
    max-width: 225px;
    margin-top: 60px;
    margin-left: 200px;
  }
  @media (max-width: 992px) {
    max-height: 98px;
    max-width: 200px;
    margin-top: 60px;
    margin-left: 150px;
  }
  @media (max-width: 777px) {
    max-height: 78px;
    max-width: 175px;
    margin-left: 100px;
  }
  @media (max-width: 580px) {
    max-height: 64px;
    max-width: 150px;
    margin-left: 0px;
  }
`;

const PressItem = styled.div`
  max-width: 550px;
  min-width: 200px;
  /* margin: 40px; */
  margin-bottom: 50px;
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    max-width: 90vw;
    min-width: 90vw;
  }
`;

const OurPressListWrapper = styled.div`
  /* background: transparent; */
  color: #fff
  padding: 30px;
  max-width: 1160px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  grid-gap: 30px;

  @media ${mobileSize} {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
`;

const Pressh4 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.WHITE_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  min-width: 200px;
  font-size: 18px;
  opacity: 0.4;
  line-height: 26px;
  margin-top: 24px;
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-top: 14px;
  }
`;

const Pressp = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  max-width: 358px;
  margin-top: 13px;
  min-width: 200px;
  line-height: 26px;
  font-size: 18px;
  a {
    text-decoration: none;
    color: ${theme.BLACK_COLOR};
    display: inline-block;
    font-weight: 300;
    line-height: 26px;
    font-size: 18px;
  }
  a:hover {
    text-decoration: none;
  }
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-top: 3px;
    a {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

const Pressh3 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.WHITE_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  max-width: 426px;
  min-width: 200px;
  font-size: 28px;
  line-height: 36px;
  margin-top: 17px;
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 24px;
    line-height: 34px;
    margin-top: 7px;
  }
`;

const Pressimg = styled.img`
  width: 100%;
  padding: 0;
  margin: 0;
`;

const ArticleSection = styled.section`
  background-color: #2f3e59;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { ForeignSegment };
